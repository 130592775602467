import Cookies from 'js-cookie';
import _ from 'lodash';
import md5 from 'md5';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { APP_IMG, NEXT_PUBLIC_TIMER_LANDING_PAGE } from 'src/constants';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import styled from 'styled-components';

const Carousel = dynamic(import('react-slick'));
const SectionLandingPage = ({ data = [] }) => {
  if (_.isEmpty(data)) return null;

  const router = useRouter();
  const [visible, setVisible] = useState(false);

  function openCookieAccept() {
    const cookieFirstPage = Cookies.get('first_page');
    if (cookieFirstPage) {
      document.getElementById('landing-page')?.remove();
    }
    setVisible(!cookieFirstPage);
  }

  async function handleAcceptDetail() {
    const _id = md5('first_page');
    const intenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);

    Cookies.set('first_page', _id, { expires: intenMinutes });
    // router.push('/bcgtalk2022');
  }
  async function handleAccept() {
    const _id = md5('first_page');
    const intenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);

    Cookies.set('first_page', _id, { expires: intenMinutes });
    router.reload(window.location.pathname);
  }

  useEffect(() => {
    openCookieAccept();
  }, [visible]);

  useEffect(() => {
    // handleCommemorate
    const getBody = document.querySelector('body');
    // handleCommemorate
    if (visible) {
      // handleCommemorate
      getBody.style.overflow = 'hidden';
      // handleCommemorate
      document.getElementById('__next')?.classList?.add('show-landing-page');
      document.getElementById('footer-landing')?.setAttribute('style', 'display:flex;');
      document.getElementById('truehits_div')?.setAttribute('style', 'display:none;');
      const timer = setTimeout(() => {
        handleAccept();
      }, (NEXT_PUBLIC_TIMER_LANDING_PAGE || 120000));
      return () => clearTimeout(timer);
    } else {
      getBody.style.overflow = 'initial';
      document.getElementById('__next')?.classList?.remove('show-landing-page');
      document.getElementById('footer-landing')?.setAttribute('style', 'display:none;');
      document.getElementById('truehits_div')?.setAttribute('style', 'display:flex;');
    }
  }, [visible]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000
  };
  return (
    <>
      <SectionWrapper
        id='landing-page'
        className='landing-wrapper'
        dataImage={{ ...data }}
      >
        <div className={`landing-banner '  ${data.length > 1 ? '' : 'container'}`}>
          <div className={data.length > 1 ? 'container pd-1' : ''}>
            {data && (
              <Carousel {...settings}>
                {data.map((item, index) => (
                  <div key={index} className='landing-item'>
                    <img src={`${APP_IMG}/${item?.imagePath}`} alt={`${item?.title}`} width='100%' height='100%' className='desktop-images' />
                    <img src={`${APP_IMG}/${item?.imagePathMobile}`} alt={`${item?.title}`} width='100%' height='100%' className='mobile-images' />
                    <div className='footer-landing'>
                      {item.url && (
                        <button id='btn-inside' onClick={() => {
                          sendDataLayerGlobal({
                            type: DATALAYER_TYPE.TRACK_INDEX,
                            router: router?.pathname,
                            section: 'landingpage',
                            data: {
                              title: item.title,
                              heading: 'ดูรายละเอียด',
                              carousel: index + 1
                            }
                          });
                          handleAcceptDetail();
                        }}>
                          <a href={item?.url} target={'_blank'} rel={'noopener noreferrer nofollow'}>
                            <span>ดูรายละเอียด</span>
                          </a>
                        </button>
                      )}
                      <button id='btn-inside' onClick={() => {
                        sendDataLayerGlobal({
                          type: DATALAYER_TYPE.TRACK_INDEX,
                          router: router?.pathname,
                          section: 'landingpage',
                          data: {
                            title: 'เข้าสู่เว็บไซต์',
                            heading: 'กรุงเทพธุรกิจ',
                            carousel: index + 1
                          }
                        });
                        handleAccept();
                      }}>
                        <span>
                          เข้าสู่เว็บไซต์ <br />
                          กรุงเทพธุรกิจ
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        </div>
      </SectionWrapper>
    </>
  );
};

const SectionWrapper = styled.section`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999999999;
  background-size: contain;
  background-color: rgba(256, 256, 256, 1);
  .slick-track {
    min-width: 2000px;
  }
  .container { 
    max-width: 1440px !important;
  }
  .landing-banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 1200px) {
      left: 0;
      transform: translate(0, -50%);
    }
    @media (max-width: 690px) {
      margin-bottom: 30px;
      top: 0%;
      left: 0%;
      right: 0;
      transform: translate(0, 0%);
    }
    @media (min-width: 1720px) {
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .landing-item {
      display: block;
      position: relative;
      width: 1300px;
      @media (max-width: 1200px) {
        width: 100%;
      }
      @media (max-width: 690px) {
        width: 600px;
      }
      @media (max-width: 600px) {
        width: 100%;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 0;
      &.desktop-images {
        display: block;
        @media (max-width: 690px) {
          display: none;
        }
      }
      &.mobile-images {
        display: none;
        @media (max-width: 690px) {
          display: block;
        }
      }
    }
  }
  .slick-dots li button {
      background: white;
    }
  .slick-slide div {
    display: flex;
    justify-content: center;
  }
  .footer-landing {
    position: absolute;
    bottom: 10%;
    left: 50%;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    width: 100%;
    transform: translate(-50%, -10%);
    @media (max-width: 475px) {
      bottom: 6%;
    }
    /* @media (max-width: 768px) {
      margin: 60px 0 30px 0;
    } */
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 50px;
    /* max-width: 200px; */
    /* margin: 20px 30px 20px auto; */
    padding: 5px 10px;
    cursor: pointer !important;
    background: #fff;
    color: #0600E7;
    border: none;
    border-radius: 50px;
    box-shadow: 0px 2px 3px #c3c3c3;
    font-family: 'Prompt', sans-serif;
    @media (max-width: 1024px) {
      width: 130px;
      height: 35px;
      font-size: 14px;
      line-height: 1;
    }

    span {
      color: #0600E7;
      font-size: 14px;
      @media (max-width: 475px) {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 767px) {
    .footer-landing {
      justify-content: center;
    }
  }

  @media (max-width: 475px) {
    button {
      max-width: 150px;
      font-size: 16px;
    }
    .pd-1 {
      padding: 0.8rem;
    }
    .slick-dots {
      bottom: 10px !important;
    }
   
  }
`;

export default SectionLandingPage;
